import { Link } from "gatsby"
import React from "react"
import { Fade } from "react-reveal"
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import Layout from "../components/Layout"
import _ from "lodash"
import { flagLarge } from "./flags"
import {
  ArrowCircleLeftOutline,
  ChartSquareBarOutline,
  CurrencyDollarOutline,
} from "heroicons-react"
import FXWidgetCTA from "../components/FXWidget/FXWidgetCTA"

const standardDeviation = (arr, usePopulation = false) => {
  const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length
  return Math.sqrt(
    arr
      .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
      .reduce((acc, val) => acc + val, 0) /
      (arr.length - (usePopulation ? 0 : 1))
  )
}

export default ({
  pageContext: {
    country: { name, countryCode, taxes_and_duties, fx_rates, currencyCode },
  },
}) => {
  const fxRates = fx_rates.map(({ value, date }) => {
    return { date, value: Math.round(1000 * value) / 1000 }
  })

  const sortedRates = _.sortBy(fxRates, "value")
  const dailyReturns = fx_rates
    .map((__value, idx) => {
      return fx_rates[idx + 1]?.value / fx_rates[idx]?.value - 1
    })
    .filter(item => item)

  const std = standardDeviation(dailyReturns)
  const volatilityLevel = +(100 * Math.sqrt(dailyReturns.length) * std).toFixed(
    2
  )

  const AxisTick = ({ x, y, payload: { value }, axis = "X" }) => {
    let label
    let dy = 16
    let textAnchor = "middle"

    if (axis === "X") {
      const date = new Date(value)

      if (date.getDate() === 1 && date.getMonth() === 0) {
        label = date.getFullYear()
      } else {
        label =
          date.toLocaleDateString("en", { month: "short" }) +
          " " +
          date.getDate()
      }
    } else {
      label = value
      textAnchor = "end"
      dy = 4
    }

    return (
      <g>
        <text
          x={x}
          y={y}
          dy={dy}
          textAnchor={textAnchor}
          className="text-xs"
          fill="#9CA3AF"
        >
          {label}
        </text>
      </g>
    )
  }

  const ratesUp = fxRates[fxRates.length - 1].value > fxRates[0].value
  const startColor = ratesUp ? "#10B981" : "#EF4444"
  const endColor = ratesUp ? "#34D399" : "#FCA5A5"
  const strokeColor = ratesUp ? "#047857" : "#B91C1C"
  const YoYGrowth =
    Math.round(
      10000 * (fxRates[fxRates.length - 1].value / fxRates[0].value - 1)
    ) / 100

  const formatPrice = val =>
    new Intl.NumberFormat("us-EN", {
      currency: currencyCode,
      style: "currency",
    }).format(val)

  const Card = ({ label, value, date, colorize = null, volatility = null }) => {
    let background = "bg-gray-50"
    let border = "border-gray-300"
    let gradient = "from-gray-200 to-gray-100"
    let smallText = "text-gray-400"
    let title = "text-gray-800"
    let valueColor = "text-gray-700"

    if (colorize) {
      background = YoYGrowth > 0 ? "bg-green-50" : "bg-red-50"
      border = YoYGrowth > 0 ? "border-green-300" : "border-red-300"
      gradient =
        YoYGrowth > 0
          ? "from-green-200 to-green-100"
          : "from-red-200 to-red-100"
      smallText = YoYGrowth > 0 ? "text-green-400" : "text-red-400"
      title = YoYGrowth > 0 ? "text-green-800" : "text-red-800"
      valueColor = YoYGrowth > 0 ? "text-green-700" : "text-red-700"
    }

    if (volatility) {
      if (volatilityLevel > 0) {
        background = "bg-green-50"
        border = "border-green-300"
        gradient = "from-green-200 to-green-100"
        smallText = "text-green-400"
        title = "text-green-800"
        valueColor = "text-green-700"
      }

      if (volatilityLevel > 5) {
        background = "bg-yellow-50"
        border = "border-yellow-300"
        gradient = "from-yellow-200 to-yellow-100"
        smallText = "text-yellow-400"
        title = "text-yellow-800"
        valueColor = "text-yellow-700"
      }

      if (volatilityLevel > 10) {
        background = "bg-red-50"
        border = "border-red-300"
        gradient = "from-red-200 to-red-100"
        smallText = "text-red-400"
        title = "text-red-800"
        valueColor = "text-red-700"
      }
    }

    return (
      <div className={`${background} border rounded shadow ${border}`}>
        <div
          className={`border-b-indigo-400 p-2 ${title} text-xs tracking-wider bg-gradient-to-r border-b ${gradient} uppercase`}
        >
          {label}
        </div>
        <div className={`my-1 px-2 py-1 text-3xl ${valueColor}`}>{value}</div>
        <div className={`px-2 py-1 ${smallText} text-xs`}>{date}</div>
      </div>
    )
  }

  return (
    <>
      <Layout
        title={`${name} Market Details | Pricelist`}
        keywords={`${name}, Market Details, Exchange Rates `}
      >
        <div class="relative mx-auto px-4 py-1 max-w-5xl sm:px-6 lg:px-8">
          <div className="group flex items-center justify-between mt-16 pb-3 border-b">
            <h1 className="flex items-center text-gray-800 text-2xl font-bold sm:text-4xl">
              <div className="flex justify-center mr-4">
                {flagLarge[countryCode]}
              </div>
              {name} Market Details
            </h1>
            <Link
              to="/markets"
              className="hidden items-center px-4 py-1 text-indigo-800 text-xs font-medium tracking-wide bg-indigo-200 rounded-md opacity-0 group-hover:opacity-100 cursor-pointer transform -translate-y-2 group-hover:translate-y-0 duration-300 sm:flex"
            >
              <ArrowCircleLeftOutline className="mr-2 w-4" />{" "}
              <span>Back To All Markets</span>
            </Link>
          </div>

          <div class="flex flex-col gap-y-8 mt-8 mx-auto text-base sm:flex-row sm:gap-x-8">
            <div className="mb-16 w-full h-96">
              <div className="mb-2">
                <h2 className="text-gray-700 text-lg font-bold">
                  {name} Exchange Rates
                </h2>

                <div className="flex items-baseline justify-between">
                  <span className="text-gray-400 text-sm font-semibold uppercase">
                    {currencyCode}/USD
                  </span>
                  <span className="text-gray-400 text-sm">Q3'20-Q3'21</span>
                </div>
              </div>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={300}
                  data={fxRates}
                  margin={{
                    top: 5,
                    right: 0,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <defs>
                    <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor={startColor}
                        stopOpacity={0.8}
                      />
                      <stop offset="95%" stopColor={endColor} stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid vertical={false} stroke="#E5E7EB" />
                  <XAxis
                    dataKey="date"
                    scale="auto"
                    tick={<AxisTick axis="X" />}
                  />
                  <YAxis
                    domain={[
                      +(sortedRates[0].value * 0.95).toFixed(3),
                      +(
                        sortedRates[sortedRates.length - 1].value * 1.05
                      ).toFixed(3),
                    ]}
                    type="number"
                    tick={<AxisTick axis="Y" />}
                  />

                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="value"
                    dot={false}
                    fill="url(#gradient)"
                    fillOpacity={1}
                    stroke={strokeColor}
                  />
                </AreaChart>
              </ResponsiveContainer>
              <div className="pt-2 text-right text-gray-400 text-xs">
                Source: European Central Bank, 2021
              </div>
            </div>
          </div>

          {["EUR", "YEN", "CAD", "GBP"].includes(currencyCode) && (
            <div className="flex justify-center mt-16 w-full text-center">
              <Link
                to="/product-pricing-and-foreign-currency-rates-estimator"
                className="block flex gap-x-4 items-center justify-center p-2 w-10/12 text-indigo-100 hover:text-white hover:underline text-lg bg-indigo-600 hover:bg-indigo-800 rounded-lg hover:shadow-lg transform hover:-translate-y-0.5 duration-300"
              >
                <ChartSquareBarOutline className="w-0 sm:w-6" />
                <>See price insights for your products in {name} for free</>
                <CurrencyDollarOutline className="w-0 sm:w-6" />
              </Link>
            </div>
          )}

          <div className="mt-16 pb-1 border-b">
            <h2 className="font-gray-600 font-bold">
              Exchange Rate Highlights
            </h2>
          </div>

          <div className="grid gap-4 grid-cols-2 mt-6 text-gray-500 sm:grid-cols-4">
            <Card
              label={"Lowest Rate"}
              value={formatPrice(sortedRates[0].value)}
              date={sortedRates[0].date}
            />

            <Card
              label={"Highest Rate"}
              value={formatPrice(sortedRates[sortedRates.length - 1].value)}
              date={sortedRates[sortedRates.length - 1].date}
            />

            <Card
              label={"Volatility"}
              value={<>{volatilityLevel}%</>}
              volatility
            />

            <Card
              label={
                <>
                  <abbr title="Year-on-Year growth">YoY</abbr> Growth:
                </>
              }
              value={<>{YoYGrowth}%</>}
              colorize
            />
          </div>

          <div className="mt-16 pb-1 border-b">
            <h2 className="font-gray-600 font-bold">
              Consumer Taxes Summary in {name}
            </h2>
          </div>

          {countryCode !== "CA" && (
            <div className="grid gap-4 grid-cols-2 mt-6 text-gray-500 sm:grid-cols-4">
              <Card
                label={taxes_and_duties[0]?.tax_name}
                value={<>{taxes_and_duties[0]?.tax_value}%</>}
              />
            </div>
          )}

          {countryCode == "CA" && (
            <div className="grid gap-4 grid-cols-2 mt-4 text-gray-500 sm:grid-cols-4">
              <div className="col-span-full">
                <p className="py-2 leading-relaxed">
                  Canada has mutliple tax rates that are combined and calculated
                  at the checkout. The federal Goods and Services Tax is charged
                  in all provinces at 5%.
                </p>
                <p className="py-2 leading-relaxed">
                  Select provinces &mdash; British Columbia, Manitoba , New
                  Brunswick , Newfoundland, Nova Scotia , Ontario, Prince Edward
                  Island, Quebec, and Saskatchewan &mdash; charge additional 6%
                  to 10% for Provincial Sales Tax.
                </p>

                <p className="py-2 leading-relaxed">
                  In contrast, Alberta, Northwest Territories, and Yukon charge
                  no Provincial Sales Tax.
                </p>

                <p className="py-2 leading-relaxed">
                  The combined consumer tax burden for purchases of goods and
                  services therefore varies between 5% to 15%.
                </p>
              </div>
            </div>
          )}
          <div className="mt-16">
            <Fade duration={3000}>
              <FXWidgetCTA
                url="/contact-sales"
                linkLabel="Get in touch"
                mainText="Get better at pricing. Try Pricelist today."
              />
            </Fade>
          </div>
        </div>
      </Layout>
    </>
  )
}
